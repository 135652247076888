<template>
  <div class="container">
    <div class="row mb-4">
      <div class="col-sm-4">
        <img
          src="../../assets/images/logo-valorem-pay.png"
          alt="homepage"
          class="dimensao-logo"
        />
      </div>
      <div class="col-sm-8 text-center">
        <p class="text-danger destaque1">{{ $t("CHECKOUT.ERRO_PAGAMENTO") }}</p>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-sm-12 text-center">
        <p class="text-danger destaque2">
          {{ $t("CHECKOUT.ERRO_PAGAMENTO2") }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  mounted() {},
  methods: {
    fecharAba() {
      window.close();
    },
  },
};
</script>
<style scoped>
.container {
  margin-top: 180px;
  width: 600px;
  margin-left: auto;
  margin-right: auto;
}
.destaque1 {
  font-size: 1rem;
  font-weight: bold;
}
.destaque2 {
  font-size: 1.5rem;
  font-weight: bold;
}
</style>