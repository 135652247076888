import Vue from "vue";
import Router from "vue-router";
import verificaAutenticacao from "@/common/middleware/verificaAutenticacao.js";
import redirecionaSeAutenticado from "@/common/middleware/redirecionaSeAutenticado.js";

import Estabelecimentos from "./Estabelecimentos";
import Usuarios from "./Usuarios";
import Transacoes from "./Transacoes";
import Relatorios from "./Relatorios";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,

  routes: [
    {
      path: "/",
      redirect: "/transacoes",
      component: () => import("@/layouts/full-layout/FullLayout"),
      children: [
        {
          name: "transacoes",
          path: "/transacoes",
          meta: {
            middleware: verificaAutenticacao,
          },
          component: () => import("@/views/transacoes/Index"),
        },
        ...Usuarios,
        ...Estabelecimentos,
        ...Transacoes,
        ...Relatorios
      ],
    },
    {
      path: "/",
      component: () => import("@/layouts/blank-layout/Blanklayout"),
      children: [
        {
          name: "Login",
          path: "login",
          meta: {
            middleware: redirecionaSeAutenticado,
          },
          component: () => import("@/views/autenticacao/Login"),
        },
        {
          name: "esqueceu-sua-senha",
          path: "esqueceu-sua-senha/",
          meta: {
            middleware: redirecionaSeAutenticado,
          },
          component: () => import("@/views/autenticacao/EsqueceuSenha"),
        },
        {
          name: "checkout",
          path: "/checkout/:id",
          component: () => import("@/views/checkout/Index"),
        },
        {
          name: "checkout-erro",
          path: "/checkout-erro",
          component: () => import("@/views/checkout/ErroPagamento"),
        },
      ],
    },
    {
      path: "*",
      redirect: "/login",
    },
    {
      path: "/",
      redirect: "/login",
    },
  ],
});

import NProgress from "nprogress";

router.beforeResolve((to, from, next) => {
  if (to.name) {
    NProgress.start(800);
  }
  next();
});

router.afterEach(() => {
  NProgress.done();
});

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];

  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    context.next(...parameters);
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }

  return next();
});

export default router;
